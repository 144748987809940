import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ps_line = _resolveComponent("ps-line")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    modelValue: $setup.isOpen,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.isOpen) = $event)),
    fullscreen: $props.isFullscreen,
    "modal-transition": $props.modalTransition,
    "click-out": $props.isClickOut,
    "disable-motion": $props.isMotionDisabled,
    teleportTarget: "#app",
    "max-width": !$props.isFullscreen ? $props.maxWidth : undefined,
    "remove-backdrop": $props.isBackdropRemoved,
    onAfterLeave: _cache[1] || (_cache[1] = ($event: any) => ($setup.activeModal('false'))),
    onAfterEnter: _cache[2] || (_cache[2] = ($event: any) => ($setup.activeModal('true')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass([$props.theme, "overflow-y-auto max-h-128 bg-background dark:bg-backgroundDark"])
      }, [
        _renderSlot(_ctx.$slots, "title", {}, undefined, true),
        _createVNode(_component_ps_line, {
          class: _normalizeClass(["mb-4", $props.line])
        }, null, 8, ["class"]),
        _createElementVNode("div", {
          class: _normalizeClass(["mb-4 overflow-y-auto", $props.bodyHeight])
        }, [
          _renderSlot(_ctx.$slots, "body", {}, undefined, true)
        ], 2),
        _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
      ], 2)
    ]),
    _: 3
  }, 8, ["modelValue", "fullscreen", "modal-transition", "click-out", "disable-motion", "max-width", "remove-backdrop"]))
}