
import { ref, onMounted, onUnmounted } from 'vue';
import { PsValueProvider } from '@/store/modules/core/PsValueProvider';

export default {
    name:"PsDropdown",
    props: {
        align : {
            type : String,
            default : 'right'
        },
        h: {
            type : String,
            default : ' h-56 '
        }
    },
    emits : [
        'on-click'
    ],
    setup(_, { emit }) {

        const psValueHolder = PsValueProvider.psValueHolder;

        const isMenuOpen = ref(false);
        function hide() {
            if(isMenuOpen.value) {
                isMenuOpen.value = !isMenuOpen.value;
            }
        }

        const dropdown = ref();
        function close(e) {
            if(!dropdown.value.contains(e.target)) {
                hide();
            }
        }

        onMounted(() => {
            document.addEventListener('click', close)
        });

        onUnmounted(() => {
            document.removeEventListener('click', close)
        });

        function clicked() {

            isMenuOpen.value = !isMenuOpen.value;
            emit('on-click');

        }

        return {
            isMenuOpen,
            dropdown,
            hide,
            clicked,
            psValueHolder
        }
    }
}
