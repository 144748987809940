

// libs
import { defineComponent, onMounted , onUnmounted, ref} from 'vue';
import { useRoute } from 'vue-router';
import { i18n } from '@/assets/locales/index';

// Providers
import { PsValueProvider } from './store/modules/core/PsValueProvider';
import { usePsAppInfoProviderState } from './store/modules/appinfo/AppInfoProvider';

// Holders
import { usePsValueHolderState } from '@/object/core/PsValueHolder';
import AppInfoParameterHolder from './object/holder/AppInfoParameterHolder';

// Components
import FooterView from '@/views/general/FooterView.vue';
import PsNavTabBar from '@/components/layouts/navbar/PsNavTabBar.vue';
import PsNavBar from '@/components/layouts/navbar/PsNavBar.vue';
import PsIcon from './components/core/icons/PsIcon.vue';

export default defineComponent({
    name : "AppView",
    components: {
        PsNavBar,
        FooterView,
        PsIcon,
        PsNavTabBar
    },
  
    setup() {
    
        PsValueProvider.psValueHolder = usePsValueHolderState();
        const appInfoProvider = usePsAppInfoProviderState();
        const atTopOfPage = ref(true);
        const route = useRoute();
        const showEntryMessage = ref(true);
        const psValueHolder = PsValueProvider.psValueHolder;
        const loginUserId = psValueHolder.getLoginUserId();

        function handleScroll(){
            // when the user scrolls, check the pageYOffset
            if(window.pageYOffset>30){
                // user is scrolled
                if(atTopOfPage.value) atTopOfPage.value = false;
            }else{
                // user is at top of page
                if(!atTopOfPage.value) atTopOfPage.value = true;
            }
        }

        onMounted(() =>{
            window.addEventListener('scroll', handleScroll);
            document.title = i18n.global.t('ps_nav_bar__ps_buy_sell');

            const appInfoParameterHolder = new AppInfoParameterHolder();
            appInfoParameterHolder.userId = loginUserId;
            appInfoProvider.loadDeleteHistory(appInfoParameterHolder);
        })

        onUnmounted(() => {
            window.removeEventListener('scroll', handleScroll);
        })

        

        return {
            atTopOfPage,
            route,
            showEntryMessage,
            psValueHolder
        }
    }

})

